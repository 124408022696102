import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Stack,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  Progress,
  Text,
  Button,
  Image,
  Badge,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  OrderedList,
  ListItem,
  useToast,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  List,
  Checkbox,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useState } from "react";
import Cropper from "react-cropper";
import CustomInput from "../components/CustomInput";
import PrimaryButton from "../components/PrimaryButton";
import "cropperjs/dist/cropper.css";
import { useEffect } from "react";
import { getUploadUrl, uploadImage } from "../services/api/user";
import { addQuote, JobDescription } from "../services/api/quote";
import { apiErrorHandler } from "../services/api/httpClient";
import moment from "moment";
import { useUserContext } from "../services/userContext";

interface MultiOb {
  title: string;
  jds: JobDescription[];
}
const totalSteps = 9;
const aspect = 11 / 7.5;
function urltoFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}
const titleOptions = [
  { title: "Create a Custom Title", key: 0 },
  { title: "Coating the parapet wall using Weathershield", key: 1 },
  {
    title: "Capping the chimney Stack using a heavy-duty fibre cement board:",
    key: 2,
  },
  {
    title: `Replacing defective downpipe using a new downpipe:`,
    key: 3,
  },
  { title: `Replacing faulty gutter using new Colorbond gutter:`, key: 4 },
  { title: `Repointing ridge tiles using Flexipoint cement:`, key: 5 },
  { title: `Replacing defected valley using a new valley`, key: 6 },
  {
    title: `Replacing the faulty vent pipe flashing using new DEKTITE flashing:`,
    key: 7,
  },
  { title: `Replacing broken/cracked tiles on the roof:`, key: 8 },
  { title: `Clearing gutters from leaves and debris:`, key: 9 },
  {
    title: `Replacing existing metal sheets (incl. insulation and battens):`,
    key: 10,
  },
  {
    title: `Replacing metal sheets only (NO insulation or battens):`,
    key: 18,
  },
  {
    title: `Capping chimney pots using new Colorbond custom-made chinaman cowls:`,
    key: 11,
  },
  { title: `Coating the chimney stack using Weathershield:`, key: 12 },
  { title: `Installing new Whirlybirds:`, key: 13 },
  { title: `Replacing defective skylight using a new skylight:`, key: 14 },
  {
    title: `Replacing defective apron flashings using new custom-made apron flashings:`,
    key: 15,
  },
  { title: `Pruning tree branches away from the roof surface:`, key: 16 },
  { title: `Replacing rusted/deteriorated screws using new screws:`, key: 17 },
  { title: `Securing a loose gutter section:`, key: 19 },
  { title: `Installing valley clips to loose tiles:`, key: 20 },
  { title: `Replacing a box gutter:`, key: 21 },
  {
    title: `Installation of ceiling batts (NOT sarking or insulation blankets):`,
    key: 22,
  },
  {
    title: `Replacing Tilied Roof:`,
    key: 23,
  },
  {
    title: `Replacing Polycarbonate sheets:`,
    key: 24,
  },
  {
    title: `Replacing Fiberglass (NOT polycarbonate!! Sheets):`,
    key: 25,
  },
  {
    title: `Asbestos Removal Procedure:`,
    key: 26,
  },
  {
    title: `Replacing damaged eaves:`,
    key: 27,
  },
  {
    title: `Box gutter service:`,
    key: 28,
  },
];
export const ownerList = ["Strata", "Real Estate", "Private Job"];

export const descriptionOptions = [
  "1 of fractured/broken tiles we quote replacing:",
  "Roof overview:",
  "The roof section above the leak area:",
  "Gaps and penetrations beneath the ridges, we recommend repointing using Flexipoint:",
  "We quote repointing these ridges using Flexipoint cement:",
  "Gaps and penetrations in the flashing’s joints, we quote getting re-sealed:",
  "Cracks and penetrations in the brick wall contributing to leaks below:",
  "The chimney stack we quote capping:",
  "We quote the gutter clearing of this entire complex (six maps):",
  "The roof section, we quote replacing:",
];
export const strataList = [
  "Absolute Strata Management",
  "BCS Strata Management",
  "Bright & Duggan Strata Management",
  "First Strata",
  "Kooper & Levi Strata Management",
  "Michael Roberts Strata Management",
  "O’NEILL Strata Management",
  "Neighbourly Strata",
  "Peter Clisdell Pty Ltd",
  "Result Property Group",
  "Robert R, Andrew-Campsie",
  "Strata Plus",
  "Titles Strata Management",
  "<type custom title>",
];
export const realestateList = [
  "1ST CITY REAL ESTATE GROUP",

  "Ballard Property Group",

  "Belle Property",
  "BresicWhitney Property Management",

  "Coogee Real Estate (CRE)",
  "IB Property Group Pty Ltd",

  "McGrath Estate Angents",
  "My Place Estate Agents",

  "Raine & Horne Double Bay | Bondi Beach",
  "Ray White",

  "Snowden Jones",
  "<type custom title>",
];

export const rayWhiteList = [
  "Ray White Annandale",
  "Ray White Beverly Hills",
  "Ray White Double Bay",
  "Ray White Kingsgrove",
  "Ray White Newtown",
  "Ray White Pyrmont",
  "Ray White Taylor and Partners",
];

export const cacheKey = "QUOTE-CACHE";
export default function Quote() {
  const { user } = useUserContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isObOpen, setIsObOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(1);
  const [newJD, setNewJD] = useState("");
  const [newJDTitle, setNewJDTitle] = useState("");
  const [newJDText, setNewJDText] = useState("");
  const [newJDStoreyText, setNewJDStoreyText] = useState("");
  const [storeyOption, setStoreyOption] = useState("");
  const [warrantyYear, setWarrantyYear] = useState("");
  const [warrantyNotePlus, setWarrantyNotePlus] = useState(false);
  const [warrantyNoteChoice, setWarrantyNoteChoice] = useState("");
  const [ceilingTreatment, setCeilingTreatment] = useState(false);
  const [paintTreatment, setPaintTreatment] = useState(false);
  const [importantNote, setImportantNote] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [owner, setOwner] = useState("");

  const [accessOption, setAccessOption] = useState("");
  const [tile, setTile] = useState("");
  const [cowls, setCowls] = useState("");
  const [sides, setSides] = useState("");
  const [unitType, setUnitType] = useState("Unit");
  const [unitNum, setUnitNum] = useState("");
  const [brand, setBrand] = useState("");
  const [roofingIssue, setRoofingIssue] = useState("Press to Select");
  const [roofingIssueText, setRoofingIssueText] = useState("");
  const [buildingHeight, setBuildingHeight] = useState("Press to Select");
  const [buildingHeightText, setBuildingHeightText] = useState("");
  const [observationExpress, setObservationExpress] =
    useState("Press to Select");
  const [observationType, setObservationType] = useState("Customized Quote");
  const [tileType, setTileType] = useState("Terracotta");
  const [color, setColor] = useState("");
  const [meters, setMeters] = useState("");
  const [sheet, setSheet] = useState("");
  const [zorc, setZorc] = useState("Zincalume");
  const [isValley, setIsValley] = useState("Yes");
  const [roofType, setRoofType] = useState("Press to Select");
  const [sheetType, setSheetType] = useState("TRIMDEK");
  const [sheetTypeOption, setSheetTypeOption] = useState("Press to Select");
  const [cOrP, setCOrP] = useState("");
  const [size, setSize] = useState("");
  const [sizeOption, setSizeOption] = useState("");
  const [valleyMeter, setValleyMeter] = useState("");
  const [strataPlan, setStrataPlan] = useState("");

  const [landlordPhone, setLandLordPhone] = useState("");
  const [landlordEmail, setLandLordEmail] = useState("");
  const [ownerChoice, setOwnerChoice] = useState("Press to Select");
  const [realestateChoice, setRealestateChoice] = useState("Press to Select");
  const [realestateSubChoice, setRealestateSubChoice] =
    useState("Press to Select");
  const [strataChoice, setStrataChoice] = useState("Press to Select");
  const [realestate, setRealestate] = useState("Press to Select");
  const [strata, setStrata] = useState("Press to Select");
  const [jobNo, setJobNo] = useState("");
  const [jdIndex, setJdIndex] = useState(2);
  const [JDPrice, setJDPrice] = useState(0);
  const [siteAddress, setSiteAddress] = useState("");
  const [observation, setObservation] = useState("");
  const [description, setDescription] = useState("");
  const [ridge, setRidge] = useState("");
  const [skylight, setSkylight] = useState("");
  const [skylightOption, setSkylightOption] = useState("");
  const [colorOption, setColorOption] = useState("Press to Select");
  const [titleSelect, setTitleSelect] = useState(0);
  const [estimation, setEstimation] = useState("");
  const [difficult, setDifficult] = useState("Press to Select");
  const [screwType, setScrewType] = useState("50mm T17s");
  const [screwTypeValue, setScrewTypeValue] = useState("");
  const [screwNum, setScrewNum] = useState("");

  const [descriptionOption, setDescriptionOption] = useState("Express options");
  const [requiredAccess, setRequiredAccess] = useState("");
  const [requiredAccessText, setRequiredAccessText] = useState("");
  const [men, setMen] = useState("");
  const [effort, setEffort] = useState("");

  const [unit, setUnit] = useState("hours");

  const [sections, setSections] = useState<
    { src: string; description: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [multiOb, setMultiOb] = useState<MultiOb[]>([]);
  const [multiObItems, setMultiObItems] = useState<JobDescription[]>([]);
  const [jobDescription, setJobDescription] = useState<JobDescription[]>([
    {
      title: "",
      description:
        "1. Supply labour to set up and use harnesses, ropes, and temporary anchor points to facilitate works as required to comply with OH&S.",
    },
  ]);
  const toast = useToast();
  useEffect(() => {
    try {
      const cache = window.localStorage.getItem(cacheKey);
      if (cache) {
        const data = JSON.parse(cache);
        setStep(data.step);
        setDate(data.date);
        setOwnerChoice(data.ownerChoice);
        setRealestateChoice(data.realestateChoice || "Press to Select");
        setRealestateSubChoice(data.realestateSubChoice || "Press to Select");
        setOwner(data.owner);
        setRealestate(data.realestate);
        setLandLordPhone(data.landlordPhone);
        setStrataChoice(data.strataChoice || "Press to Select");
        setStrata(data.strata || "Press to Select");
        setRealestate(data.realestate || "Press to Select");
        setLandLordEmail(data.landlordEmail);
        setStrataPlan(data.strataPlan);
        setJobNo(data.jobNo);
        setSiteAddress(data.siteAddress);
        setRoofingIssue(data.roofingIssue || "Press to Select");
        setBuildingHeight(data.buildingHeight || "Press to Select");
        setRoofingIssueText(data.roofingIssueText);
        setBuildingHeightText(data.buildingHeightText);
        setObservationExpress(data.observationExpress || "Press to Select");
        setObservationType(data.observationType || "Customized Quote");
        setObservation(data.observation);
        setMultiOb(data.multiOb || []);
        setSections(data.sections || []);
        setJobDescription(data.jobDescription || []);
        setNewJDText(data.newJDText);
        setNewJDTitle(data.newJDTitle);
        setNewJD(data.newJD);
        setMen(data.men);
        setWarrantyYear(data.warrantyYear);
        setEffort(data.effort);
        setUnit(data.unit || "hours");
        setRequiredAccessText(data.requiredAccessText);
        setRequiredAccess(data.requiredAccess);
        setDifficult(data.difficult || "Press to Select");
        setEstimation(data.estimation);
        setWarrantyNoteChoice(data.warrantyNoteChoice);
        setWarrantyNotePlus(data.warrantyNotePlus);
        setCeilingTreatment(data.ceilingTreatment);
        setPaintTreatment(data.paintTreatment);
        setImportantNote(data.importantNote);
        setNewJDStoreyText(data.newJDStoreyText);
        setLandLordEmail(data.landlordEmail);
        setLandLordPhone(data.landlordPhone);
        setStrataPlan(data.strataPlan);
        setStrataChoice(data.strataChoice || strataList[0]);
      }
    } catch (error) {}
    setTimeout(() => {
      setMounted(true);
    }, 1000);
  }, []);
  useEffect(() => {
    if (mounted) {
      const data = {
        step,
        date,
        owner,
        realestateChoice,
        realestateSubChoice,
        realestate,
        strata,
        strataChoice,
        strataPlan,
        ownerChoice,
        landlordEmail,
        landlordPhone,
        jobNo,
        siteAddress,
        observation,
        roofingIssue,
        buildingHeight,
        roofingIssueText,
        buildingHeightText,
        observationExpress,
        sections: sections || [],
        jobDescription: jobDescription || [],
        multiOb: multiOb || [],
        newJDText,
        newJDStoreyText,
        newJD,
        newJDTitle,
        effort,
        unit,
        men,
        requiredAccess,
        requiredAccessText,
        difficult,
        observationType: observationType || "Customized Quote",
        estimation,
        warrantyYear,
        warrantyNoteChoice,
        warrantyNotePlus,
        ceilingTreatment,
        paintTreatment,
        importantNote,
      };
      window.localStorage.setItem(cacheKey, JSON.stringify(data));
    }
  }, [
    strataPlan,
    strata,
    strataChoice,
    landlordEmail,
    newJDStoreyText,
    landlordPhone,
    multiOb?.length,
    observationType,
    roofingIssue,
    buildingHeight,
    roofingIssueText,
    buildingHeightText,
    observationExpress,
    mounted,
    ownerChoice,
    realestateChoice,
    realestateSubChoice,
    step,
    date,
    owner,
    realestate,
    jobNo,
    siteAddress,
    observation,
    sections?.length,
    jobDescription?.length,
    newJDText,
    newJD,
    newJDTitle,
    effort,
    unit,
    men,
    requiredAccess,
    requiredAccessText,
    difficult,
    estimation,
    warrantyYear,
    warrantyNoteChoice,
    warrantyNotePlus,
    ceilingTreatment,
    paintTreatment,
    importantNote,
  ]);

  const [crop, setCrop] = useState<any>();

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader?.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  const formatJD = (jd: string, startIndex: number) => {
    jd = `${startIndex}. ` + jd;
    startIndex += 1;
    const list = jd.split("\n");
    let result = "";
    list.forEach((n, i) => {
      if (i === 0) {
        result += n;
      } else {
        result += `\n${startIndex}. ${n}`;
        startIndex += 1;
      }
    });
    return { startIndex, result };
  };
  const formatJobDescriptions = (jds: JobDescription[]) => {
    let currentIndex = 2;
    return jds.map((jd, i) => {
      if (i === 0) {
        return jd;
      }
      const { result, startIndex } = formatJD(jd.description, currentIndex);
      currentIndex = startIndex;
      return { ...jd, description: result };
    });
  };

  const handlePrefill = (option: number, saveMultiOb?: boolean) => {
    let nextNewJDTitle = newJDTitle;
    let nextNewJD = newJD;
    let price = 0;
    switch (option) {
      case 1:
        nextNewJDTitle = `Coating the parapet wall (around ${meters} linear meters) using a Weathershield (Colour: ${color}):`;
        nextNewJD = `Use a wire brush to remove any dirt build-up, loose paint and cement chunks from the parapet wall. \nPrime the parapet wall using the Prepcoat primer (around 4 linear meters).\nFill in any holes and cracks using a combination of Flexipoint cement and external gap filler.\nCoat the parapet wall using a Weathershield (Colour: ${color}) around ${meters} linear meters.`;
        price = 250;
        break;
      case 2:
        nextNewJDTitle = `Capping the chimney Stack using a heavy-duty fibre cement board:`;
        nextNewJD = `Remove any loose chunks of cement and debris from the chimney’s top. \nUse roofing bedding mortar to cement in place the new heavy-duty fibre cement board.\nRepoint the new fibre cement board using Flexipoint cement on its perimeter.\nCoat the new fibre cement board using Weathershield.`;
        price = 150;
        break;
      case 3:
        nextNewJDTitle = `Replacing defective downpipe using a new ${
          cOrP === "PVC" ? cOrP : `Colorbond (Colour: ${color})`
        } (${size}) downpipe (around ${meters} linear meters):`;
        nextNewJD = `Remove and dispose of the defective downpipe (around ${meters} linear meters).\nSupply and install a new ${cOrP} downpipe (around ${meters} linear meters).\nConnect the new downpipe to the existing gutter’s nozzle.\nSecure the new downpipe to the wall using new astragals.`;
        price = 25 * Number(meters);
        break;
      case 4:
        nextNewJDTitle = `Replacing faulty gutter using new Colorbond (Colour: ${color}) gutter (around ${meters} linear meters):`;
        nextNewJD = `Remove the faulty gutter and dispose of it.\nClear the fascia board from any old fixings or nails.\nSupply and install a new Colorbond (Colour: ${color}) gutter (around ${meters} linear meters).\nSupply and install a new nozzle to the gutter.\nConnect the new gutter to the existing downpipe.`;
        price = 25 * Number(meters);
        break;
      case 5:
        nextNewJDTitle = `Repointing ${ridge} ridge tiles using Flexipoint (Colour: ${color}) cement (around ${meters} linear meters):`;
        nextNewJD = `Use a combination of a wire brush and a grinder to remove any loose cement and debris (moss, dirt, etc.) from the ridge tiles.\nFill in any significant gaps beneath the ridge tiles (as necessary) using roofing bedding mortar.\nRepoint ${ridge} ridge tiles using Flexipoint cement (around ${meters} linear meters).`;
        price = (Number(ridge) / 40) * 50;
        break;
      case 6:
        nextNewJDTitle = `Replacing defected valley using a new ${
          zorc === "Zincalume" ? `Zincalume` : `Colorbond (Colour: ${color})`
        } valley on a ${roofType} (around ${meters} linear meters):`;
        nextNewJD = `Cut into the bedding cement to loosen 3-4 ridge tiles above the valley.\nRemove 3-4 ridge tiles and set them aside safely on the roof for later reinstallation.\nRemove 2-3 dozen roof tiles from both sides of the valley and set them aside safely on the roof for later reinstallation.\nRemove the valley and lower it safely off the roof onto the ground to dispose of it.\nSupply and install new ${
          zorc === "Zincalume" ? `Zincalume` : `Colorbond (Colour: ${color})`
        } valley (around ${meters} linear meters).\nUse roofing bedding mortar to cement back in place 3-4 ridge tiles above the valley.\nRepoint 3-4 ridge tiles using Flexipoint cement.`;
        price = 60 * Number(meters);
        break;
      case 7:
        nextNewJDTitle = `Replacing the faulty vent pipe flashing using new DEKTITE flashing on a ${roofType}:`;
        if (roofType === "tiled roof") {
          nextNewJD = `Dismantle a few tiles above the faulty vent pipe flashing to access it.\nRemove and dispose of the faulty vent pipe flashing.\nSupply and install a new Wakaflex DEKTITE flashing to the vent pipe.\nSecure roof tiles back in their original place.`;
        } else {
          if (zorc === "Zincalume") {
            nextNewJDTitle = `Replacing the faulty vent pipe flashing using new DEKTITE flashing, including installation of the new custom-made ${roofType} back tray (around ${meters} linear meters):`;
            nextNewJD = `Loosen the top flashing to gain access under it.\nRemove faulty DEKTITE and dispose of it.\nSupply and install a new Zincalume back tray (around ${meters} linear meters).\nSupply and install a new DEKTITE to the vent pipe.\nRivet and seal all joints of the new back tray to ensure it is watertight.\nSecure back in place the top flashing.`;
          } else {
            nextNewJDTitle = `Replacing the faulty vent pipe flashing using new DEKTITE flashing, including installation of the new custom-made ${roofType} (Colour: ${color}) back tray (around ${meters} linear meters):`;
            nextNewJD = `Loosen the top flashing to gain access under it.\nRemove faulty DEKTITE and dispose of it.\nSupply and install a new Colorbond (Colour: ${color}) back tray (around ${meters} linear meters).\nSupply and install a new DEKTITE to the vent pipe.\nRivet and seal all joints of the new back tray to ensure it is watertight.\nSecure back in place the top flashing.`;
          }
        }
        price = 60 * Number(meters);
        break;
      case 8:
        nextNewJDTitle = `Replacing ${tile} broken/cracked ${tileType} tiles (${brand}) on the roof using ${tile} ${tileType} tiles:`;
        nextNewJD = `Remove ${tile} faulty tiles from the roof and lower them safely on the ground to dispose of them.\nSupply and install ${tile} matching ${tileType} tiles.\nCut any valley-cut tiles using a grinder to fit along the valley as necessary.\nEnsure the new tiles rest securely on the batten and add galvanised fixings as necessary.`;
        price = 7 * Number(tile);
        break;
      case 9:
        nextNewJDTitle = `Clearing gutters from leaves and debris (around ${meters} linear meters):`;
        if (isValley === "Yes") {
          nextNewJD = `Clear all gutters from leaves and debris (around ${meters} linear meters).\nClear all valleys from leaves and debris (around ${valleyMeter} linear meters).\nUnblock nozzles to all downpipes.\nLower bags with leaves safely on the ground and dispose of them to the rubbish tip.`;
        } else {
          nextNewJD = `Clear all gutters from leaves and debris (around ${meters} linear meters).\nUnblock nozzles to all downpipes.\nLower bags with leaves safely on the ground and dispose of them to the rubbish tip.`;
        }
        break;
      case 10:
        nextNewJDTitle = `Replacing existing roof sheets using new ${zorc} ${
          zorc === "Colorbond" ? `(Colour: ${color})` : ""
        } ${sheetType} sheets (around ${meters} square meters) along with new timber battens, insulation blankets and all new associated custom-made apron flashings, pressure seals and cappings:`;
        nextNewJD = `Remove all flashings and cappings from the roof surface and dispose of them.\nStrip all metal sheets and dispose of them.\nSupply and install new 35x70mm timber battens(around ${meters} linear meters).\nSupply and install new insulation blankets(around ${meters} square meters).\nSupply and install new ${zorc} ${
          zorc === "Colorbond" ? `(Colour: ${color})` : ""
        }${sheetType} roofing sheets (around ${meters} square meters).\nSupply and install new custom-made ${zorc} apron flashings.\nSupply and install new custom-made ${zorc} cappings and pressure seals.\nRivet and seal all joints of new flashings to ensure those are watertight.`;
        price = 80 * Number(meters);
        break;
      case 11:
        nextNewJDTitle = `Capping chimney pots using new Colorbond custom-made chinaman cowls:`;
        nextNewJD = `Supply and install ${cowls} new custom-made Colorbond chinaman cowls.\nSecure ${cowls} new cowls in place to the chimney pots using durable adhesive.`;
        price = 250 * Number(cowls);
        break;
      case 12:
        nextNewJDTitle = `Coating the chimney stack (${sides}) using Weathershield:`;
        nextNewJD = `Use a wire brush to remove any dirt build-up, loose paint and cement chunks from the chimney stack (${sides}).\nPrime the chimney stack using the Prepcoat primer (${sides}).\nFill in any holes and cracks using a combination of Flexipoint cement and external gap filler.\nCoat the chimney stack (${sides}) using a Weathershield (Colour:${color}).`;
        price = 250;
        break;
      case 13:
        nextNewJDTitle = `Installing new Whirlybirds: `;
        nextNewJD =
          roofType === "metal roof"
            ? `Remove existing whirlybirds and safely lower them on the ground to dispose of them.\nSupply and install a new custom-made Colorbond (Colour: ${color}) back tray to the roof. \nSupply and Install ${meters} new Colorbond (Colour: ${color}) Whirlybirds with stainless steel ball bearings. \nRivet and seal all joints of the new Whirlybird to ensure they are watertight.`
            : `Remove existing whirlybirds and safely lower them on the ground to dispose of them.\nRemove 2-3 tiles and safely set them aside on the roof for later reinstallation. \nSupply and Install ${meters} new Colorbond (Colour: ${color}) Whirlybirds with stainless steel ball bearings. \nSupply and Install Wakaflex flashings to the Whirlybird (as necessary) to ensure it is watertight. \nReassemble back in place tiles around the new Whirlybird. `;
        price = 250;
        break;
      case 14:
        nextNewJDTitle = `Replacing defective skylight using a new skylight:`;
        nextNewJD =
          skylight === "acrylic dome" && roofType !== "metal roof"
            ? `Dismantle a dozen roof tiles on either side of the skylight to access it.\nRemove the defective skylight and safely lower it on the ground to dispose of it.\nSupply and install new skylight flashings (the entire new frame).\nSupply and install a new ${skylight} skylight (Colour: ${color}).\nSupply and install new Wakaflex flashings (as necessary) to ensure the new skylight is watertight.\nReassemble a dozen roof tiles back in place on either side of the new skylight.`
            : `Remove the defective skylight and safely lower it on the ground to dispose of it.\nSupply and install new skylight flashings (the entire new frame).\nSupply and install a new ${skylight} skylight (Colour: ${color}).\nSupply and install new Colorbond back tray.\nRivet and seal all joints of the new back tray to ensure it is watertight.`;
        price = skylight.includes("acrylic dome")
          ? 900
          : skylight.includes("Velux")
          ? 1400
          : 0;
        break;
      case 15:
        nextNewJDTitle =
          roofType === "tiled roof"
            ? `Replacing defective apron flashings using new Wakaflex apron flashings (around ${meters} linear meters):`
            : `Replacing defective apron flashings using new custom-made ${
                zorc === "Colorbond" ? `${zorc} (Colour: ${color})` : zorc
              } apron flashings (around ${meters} linear meters):`;
        nextNewJD =
          roofType === "tiled roof"
            ? `Dismantle 1-2 dozen roof tiles to access faulty flashings and set them aside safely on the roof for later reinstallation.\nRemove and dispose of defective flashings.\nSupply and install new Wakaflex apron flashings (numerous separate soaker flashings with sufficient overlap to make them watertight), reassembling on roof tile at a time (around ${meters} linear meters).\nSupply and install a new custom-made pressure seals (around ${meters} linear meters).`
            : `Remove and dispose of defective flashings and lower it safely onto the ground.\nSupply and install new custom-made ${zorc} apron flashings (around ${meters} linear meters).\nSupply and install a new ${zorc} pressure seals (around ${meters} linear meters). \nRivet and seal joints of new flashings to ensure those are watertight.`;
        price = 60 * Number(meters);
        break;
      case 16:
        nextNewJDTitle = `Pruning tree branches away from the roof surface:`;
        nextNewJD = `Prune tree branches away from the roof surface by around 1 linear meter.\nLower tree branches safely from the roof onto the ground below.\nDispose of the tree branches from the site to the rubbish tip.`;
        price = 50;
        break;
      case 17:
        nextNewJDTitle = `Replacing ${screwNum} rusted/deteriorated screws using new ${
          screwType === "custom" ? screwTypeValue : screwType
        } screws:`;
        nextNewJD = `Remove and dispose of around ${screwNum} rusted screws.\nSupply and install ${screwNum} new ${
          screwType === "custom" ? screwTypeValue : screwType
        } screws with new rubber washers.`;
        price = (Number(screwNum) / 100) * 80;
        break;
      case 18:
        nextNewJDTitle = `Replacing ${sheet} defective metal sheets using ${sheet} new ${
          zorc === "Colorbond" ? `Colorbond (Colour: ${color})` : zorc
        } sheets (around ${meters} linear meters each sheet):`;
        nextNewJD = `Loosen adjacent flashings as necessary to access defective roofing sheets.\nRemove ${sheet} defective roof sheets and safely lower them onto the ground to dispose of them.\nSupply and install ${sheet} new ${zorc} roofing sheets (around ${meters} linear meters each).\nUse new Grade IV roofing screws to secure new sheets in place.\nSecure adjacent flashings back in place as necessary.`;
        price = 50 * Number(meters);
        break;
      case 19:
        nextNewJDTitle = `Securing a loose gutter section (around ${meters} linear meters) back in place ensuring an adequate fall to the nearest downpipe:`;
        nextNewJD = `Remove a few dozen roof tiles above the gutter to gain access to it.\nRaise the gutter adequtly to allow rainwater flow to the nearest downpipe.\nSecure the gutter in place using roofing 50mm screws.\nSecure a few dozen roof tiles back in place.`;
        price = 30;
        break;
      case 20:
        nextNewJDTitle = `Supply and install ${meters} dozen new galvanized valley clips to secure numerous loose valley-cut tiles along the valley:`;
        nextNewJD = `Reinstate numerous loose (detached) valley-cut tiles back in place.\nSecure the valley-cut tiles in place using new galvanized valley clips to prevent valley-cut tiles from slipping away.`;
        break;
      case 21:
        nextNewJDTitle = `Replacing a defective box gutter using a new custom-made Colorbond (Colour: ${color}) box gutter (around ${meters} linear meters):`;
        nextNewJD =
          roofType === "tiled roof"
            ? `Remove a few dozen of tiles above the box gutter to access it.\nSet aside roof tiles on the roof for later reinstallation.\nUse metal shears to cut the box gutter length-wise to implode it.\nRemove and safely lower the box gutter on the ground to dispose of it.\nSupply and install a new custom-made Colorbond box gutter (around ${meters} linear meters).\nRivet and seal all box gutter joints to ensure it is watertight.\nReinstate all roof tiles back in place above the new box gutter.`
            : `Loosen metal roofing sheets above the box gutter to access it.\nUse metal shears to cut the box gutter length-wise to implode it.\nRemove and safely lower the box gutter on the ground to dispose of it.\nSupply and install a new custom-made Colorbond box gutter (around ${meters} linear meters).\nRivet and seal all box gutter joints to ensure it is watertight.\nSecure all metal roofing sheets back in their original place.`;
        price = 60 * Number(meters);
        break;
      case 22:
        nextNewJDTitle = `Supply and install new Knauf Ceiling Insulation Batts with both acoustic and thermal qualities:`;
        nextNewJD = `Open up multiple tiles to access the roof space for access and sufficient airflow and ventilation inside the roof space during the works.\nDuring the work, all personnel are to wear (mandatory) half-face respirators with P3 filtering.\nSupply and install a new Earthwool R4.0 195 x 430 x 1160mm Insulation Ceiling Batts covering the total ceiling surface area of around ${meters} square meters.\nMake sure to close all roof tiles upon job completion.`;
        break;
      case 23:
        nextNewJDTitle = `Replacing the terracotta roof with new terracotta tiles (approx. ${meters} square meters), including new sarking and timber battens:`;
        nextNewJD = `Supply and install a guardrail on the roof’s perimeter.\nStrip all terracotta tiles and dispose of them (around ${
          Number(meters) * 40
        } kg of material).\nRemove all timber battens and dispose of them.\nSupply and install new heavy-duty sarking (approx. ${meters} sqm).\nSupply and install new timber battens (approx. ${
          Number(meters) * 2.5
        } lm).\nSupply and install new terracotta roof tiles (around ${
          Number(meters) * 8
        } new tiles).\nUse roofing bedding mortar to cement ${ridge} new ridge tiles (around ${
          Number(ridge) / 2
        } linear meters).\nRepoint all ridge tiles using Flexipoint cement (around ${
          Number(ridge) / 2
        } linear meters).`;
        break;
      case 24:
        nextNewJDTitle = `Supply and install new polycarbonate (colour: ${color}) ${sheetType} sheets, around ${meters} square meters:`;
        nextNewJD = `Loosen adjacent flashings to gain access to the roof sheets.\nRemove and dispose of existing sheets.\nSupply and install noize reduction tapes to battens.\nSupply and install new polycarbonate corrugated sheets (around 40 sqm).\nSecure adjacent flashings back in place.`;
        break;
      case 25:
        nextNewJDTitle = `Replacing ${sheet} fibreglass skylights using ${sheet} new matching fibreglass sheets (around ${meters} linear meters in total):`;
        nextNewJD = `Loosen the upper metal sheet to access the first skylight.\nRemove faulty skylights (around ${meters} lm).\nSupply and install new fibreglass purlin protection strips.\nSupply and install new fibreglass skylight 2400 GSM (around ${meters} lm) using new fibreglass self-drilling screws with 32 mm weather-lock washers.\nSecure adacent flashing and the metal sheets back in place.`;
        break;
      case 26:
        nextNewJDTitle = `ASBESTOS REMOVAL PROCEDURE:`;
        nextNewJD = `Lay out plastic sheeting to capture any asbestos before the commencement of works.\nUse appropriate disposable PPE before removing asbestos (full-body suits, respirators, gloves and shoe protectors).\nRemove any asbestos material and lower those safely on the plastic sheeting on the ground.\nDouble-wrap all asbestos and PPE using plastic sheeting.\nTransport asbestos to the asbestos recycling centre (Kimbriki Road Ingleside).`;
        break;
      case 27:
        nextNewJDTitle = `Replacing damaged eaves (around ${meters} linear meters) using new eaves (including priming and coating new eaves using a Weathershield):`;
        nextNewJD = `Remove any damaged eaves and safely lower them onto the ground.\nSupply and install new eaves (around ${meters} linear meters).\nSupply and install new PVC jointing strips, as necessary.\nPrime eave eave section using Prep Coat primer.\nCoat a new eave section using a Weathershield.`;
        break;
      case 28:
        nextNewJDTitle = `Box gutter service (resealing ${meters} box gutter joints):`;
        nextNewJD = `Use a wire brush to remove any dirt/debris buildup from the box gutter joints.\nRemove any loose sealant from the box gutter’s joints.\nRe-rivet ${meters} box gutter joint using blind (sealed) aluminium rivets.\nReseal box gutter joints using Sikaflex sealant.\nApply masking tape and overseal box gutter joints using a fibre-reinforced sealer.`;
        break;
    }

    if (saveMultiOb) {
      setMultiObItems([
        ...multiObItems,
        { title: nextNewJDTitle, description: nextNewJD, price },
      ]);
      setNewJD("");
      setNewJDTitle("");
      setJDPrice(price);
    } else {
      setNewJDTitle(nextNewJDTitle);
      setNewJD(nextNewJD);
      setJDPrice(price);
    }
  };
  const getWarrantyClaims = (jds: JobDescription[]) => {
    let warrantyClaims = "";
    jds.forEach((jd) => {
      if (
        jd.title.includes(
          "Replacing the faulty vent pipe flashing using new DEKTITE flashing"
        )
      ) {
        warrantyClaims += `\nWarranty on the new Wakaflex DEKTITE we install only - not the remainder of the roof: 2 years.`;
      }
      if (jd.title.includes("Replacing defective downpipe using a new")) {
        warrantyClaims += `\nWarranty on the downpipe we install only – not the remainder of the roof: 2 years.`;
      }
      if (jd.title.includes("Replacing faulty gutter using")) {
        warrantyClaims += `\nWarranty on the gutters we install only – not the remainder of the roof: 2 years.`;
      }
      if (jd.title.includes("Replacing existing roof sheets")) {
        warrantyClaims += `\nWarranty on the new roof section we install: 2 years.\nWarranty on the materials provided by our supplier, "BlueScope Steel": 10 years.`;
      }
      if (jd.title.includes("Replacing defected valley")) {
        warrantyClaims += `\nWarranty on the valley we install only - not the remainder of the roof: 2 years.`;
      }
      if (jd.title.includes("Installing new Whirlybirds")) {
        warrantyClaims += `\nWarranty on the new whirlybird we install only – not the remainder of the roof: 2 years.`;
      }
      if (jd.title.includes("Replacing defective skylight")) {
        warrantyClaims += `\nWarranty on the new skylight we install only – not the remainder of the roof: 2 years.`;
      }

      const match = jd.title.match(/Repointing [0-9]+/);
      if (match) {
        warrantyClaims += `\nWarranty on the ${match[0].replace(
          "Repointing ",
          ""
        )} repointed ridges only - not the remainder of the roof: 2 years.`;
      }
    });
    return warrantyClaims;
  };

  const getWarrantyNote = (year: string, choice: string, plus: boolean) => {
    if (warrantyNoteChoice === "A") {
      return `The steps listed above will significantly improve the roofing condition, and we highly recommend them. It is unlikely that this roof leak will recur. However, please keep in mind that the roof is more than ${year} years old and requires ongoing maintenance. If roof-related issues occur over the next two years, we will promptly re-attend to inspect it and provide a quotation for further repairs.\n\nSuppose the owner or the owner’s corporation wishes to have a warranty on all leaks and mould developments. In that case, we recommend engaging a builder, conducting a major overhaul to the roof (possibly roof replacement) and gutters, and replacing all water-damaged, mould-infected ceiling sections internally. `;
    } else {
      if (plus) {
        return `The steps listed above will significantly improve the roofing condition, and we highly recommend them. However, we unfortunately cannot warranty any roof leaks as the roof is more than ${year} years old and requires ongoing maintenance. If roof leaks occur over the next two years, we will promptly re-attend to inspect it and provide a quotation for further repairs.\n\nSuppose the owner or the owner’s corporation wishes to have a warranty on all leaks and mould developments. In that case, we recommend engaging a builder, conducting a major overhaul to the roof (possibly roof replacement) and gutters, and replacing all water-damaged, mould-infected ceiling sections internally. `;
      }
      return `The steps listed above will significantly improve the roofing condition, and we highly recommend them. However, we unfortunately cannot warranty any roof leaks as the roof is more than ${year} years old and requires ongoing maintenance. If roof leaks occur over the next two years, we will promptly re-attend to inspect it and provide a quotation for further repairs.\n\nSuppose the owner or the owner’s corporation wishes to have a warranty on all leaks and mould developments. In that case, we recommend engaging a builder, conducting a major overhaul to the roof (possibly roof replacement) and gutters, and replacing all water-damaged, mould-infected ceiling sections internally. `;
    }
  };
  const getRealestateText = (
    choice: string,
    strataChoice: string,
    realestateChoice: string,
    realestateSubChoice: string,
    email: string
  ) => {
    if (ownerList[0] === choice) {
      return strataChoice === strataList[strataList.length - 1]
        ? strata
        : strataChoice;
    }
    if (choice === ownerList[1]) {
      return realestateChoice === realestateList[realestateList.length - 1]
        ? realestate
        : realestateChoice === "Ray White"
        ? realestateSubChoice
        : realestateChoice;
    }

    return email;
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        date: moment(date).format("D MMMM YYYY"),
        siteAddress,
        jobNo: ownerChoice === ownerList[2] ? "" : `Work Order # ${jobNo}`,
        type: "Normal",
        jdText: `${newJDStoreyText}\n${newJDText}`,
        requiredAccessText: requiredAccessText,
        effort: `${men} men * ${effort}${unit} (${difficult})`,
        estimation: estimation,
        owner:
          ownerChoice === ownerList[0]
            ? `The Owners--Strata Plan ${strataPlan}`
            : ownerChoice === ownerList[1]
            ? `${owner || "Landlord"}`
            : `${owner || "Landlord"} ${
                landlordPhone ? `(${landlordPhone})` : ""
              }`,
        realestate: getRealestateText(
          ownerChoice,
          strataChoice,
          realestateChoice,
          realestateSubChoice,
          landlordEmail
        ),
        observation,
        warrantyClaim: getWarrantyClaims(jobDescription),
        warrantyNote: getWarrantyNote(
          warrantyYear,
          warrantyNoteChoice,
          warrantyNotePlus
        ),
        paintTreatment: paintTreatment
          ? "Our recommendation entails completely removing the mould-infected render, approximately 10 mm thick, and applying a new acrylic render to the affected brick wall sections. Mere repainting of these areas would not mitigate the risk of mould recurrence. It's important to note that only acrylic render provides excellent water resistance compared to traditional white-set plaster, which tends to absorb moisture and can cause mould and blistering paint to recur within weeks. However, internal renovation work falls outside the scope of this quotation (it is NOT part of this quote to carry out ANY internal repairs). \n"
          : "",
        ceilingTreatment: ceilingTreatment
          ? "Mould is a fungus thriving within porous building materials such as wood or plasterboard (gyprock). The initial step in fungal remediation involves eradicating the roof leak's source. However, it's crucial to recognize that fungi had already permeated deep into ceiling panelling (around 8mm thick). Mere application of surface paint to affected areas invariably results in mould resurfacing, even after multiple layers of paint. Hence, removing (replacing) affected ceiling sections is essential to remove mould permanently. However, please note that any internal renovation work is not included in the scope of work (we do NOT replace ceilings). \n"
          : "",
        importantNote: importantNote
          ? "In our professional assessment, we have determined that the development of condensation alongside the wall is attributable to inadequate ventilation. Our analysis indicates that the water droplets were NOT due to the roof leak. Addressing this issue requires the implementation of improved ventilation measures. Consequently, without enhancing ventilation, similar condensation issues will probably persist in the foreseeable future. However, installing ventilation systems falls beyond the scope of our services (we do not install ventilation systems).\n"
          : "",
        jobDescriptions: formatJobDescriptions(jobDescription),
        sections: sections.map((n, i) => {
          return {
            imageUrl: n.src,
            description: n.description,
          };
        }),
      };
      await addQuote(payload);
      window.localStorage.removeItem(cacheKey);
      alert("Your Quote has been submitted!");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (e: any) {
      console.log(e);
      if (e.message) {
        alert(e.message);
      }

      const error = apiErrorHandler(e);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleSaveSection = async () => {
    if (typeof crop !== "undefined" && crop.getCroppedCanvas()) {
      try {
        setIsUploading(true);
        const file = await urltoFile(
          crop.getCroppedCanvas().toDataURL(),
          "image.png",
          "image/png"
        );
        const uploadUrls = await getUploadUrl([
          {
            contentType: file?.type || "",
          },
        ]);
        await Promise.all(
          uploadUrls.map((n, i) => uploadImage(n.uploadURL, file))
        );

        const next = [
          ...sections,
          { description, src: uploadUrls[0].uploadURL.split("?")[0] },
        ];
        setSections(next);
        setIsOpen(false);
      } catch (error: any) {
        alert(
          error?.response?.message ||
            "Fail to Upload the image, please try again"
        );
      } finally {
        setIsUploading(false);
      }
    } else {
      alert("Please upload a photo");
    }
  };
  const handleRemoveSection = (index: number) => {
    const next = [...sections];
    next.splice(index, 1);
    setSections(next);
  };
  const handleRemoveJD = (index: number) => {
    const next = [...jobDescription];
    next.splice(index, 1);
    setJobDescription(next);
  };

  const handleRemoveMultiObItem = (index: number) => {
    const next = [...multiObItems];
    next.splice(index, 1);
    setMultiObItems(next);
  };
  const handleRemoveMultiOb = (index: number) => {
    const next = [...multiOb];
    next.splice(index, 1);
    setMultiOb(next);
  };
  const handleSaveMultipleOb = () => {
    let nextMultiOb = [
      ...multiOb,
      { title: `${unitType} ${unitNum}:`, jds: multiObItems },
    ];
    setMultiOb(nextMultiOb);
    let nextOb = "";
    let nextJd: JobDescription[] = [
      {
        title: "",
        description:
          "1. Supply labour to set up and use harnesses, ropes, and temporary anchor points to facilitate works as required to comply with OH&S.",
      },
    ];
    for (const n of nextMultiOb) {
      nextOb += `${n.title}\n`;
      for (const i in n.jds) {
        const j = n.jds[i];
        if (parseInt(i) === 0) {
          nextJd.push({
            title: `\n${n.title}\n\n${j.title}`,
            description: j.description,
          });
        } else {
          nextJd.push({ title: `${j.title}`, description: j.description });
        }
        nextOb += `    -    ${
          j.title[j.title.length - 1] === ":"
            ? j.title.substring(0, j.title.length - 1)
            : j.title
        }\n`;
      }
      nextOb += "\n";
    }
    setObservation(nextOb);
    setJobDescription(nextJd);
    setMultiObItems([]);
    setIsObOpen(false);
  };

  const renderOptionContent = () => {
    return (
      <>
        <Menu>
          <MenuButton
            // maxW={40}
            background={"black"}
            color={"white"}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            <Text maxW={300} noOfLines={5}>
              {titleOptions.find((n) => n.key === titleSelect)?.title || ""}
            </Text>
          </MenuButton>
          <MenuList
            maxHeight={"50vh"}
            maxW={"100vw"}
            overflowX={"scroll"}
            overflowY={"scroll"}
          >
            {titleOptions.map((n, i) => {
              return (
                <MenuItem
                  color={"black"}
                  key={n.key}
                  onClick={() => setTitleSelect(n.key)}
                >
                  {n.title}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
        {titleSelect > 0 && (
          <Box my={4}>
            {titleSelect === 1 && (
              <Box>
                <FormLabel color={"black"} htmlFor="title">
                  How many linear meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  What Colour?
                </FormLabel>
                <CustomInput
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 3 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Colorbond or PVC:
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {cOrP}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem color={"black"} onClick={() => setCOrP("PVC")}>
                      PVC
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setCOrP("Colorbond")}
                    >
                      Colorbond
                    </MenuItem>
                  </MenuList>
                </Menu>
                {cOrP === "Colorbond" && (
                  <>
                    <FormLabel color={"black"} htmlFor="title">
                      What color?
                    </FormLabel>
                    <CustomInput
                      value={color}
                      onChange={(e) => {
                        setColor(e.target.value);
                      }}
                    />
                  </>
                )}

                <FormLabel color={"black"} htmlFor="title">
                  What size?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {size}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    {cOrP === "Colorbond" && (
                      <>
                        <MenuItem
                          color={"black"}
                          onClick={() => {
                            setSizeOption("50x100mm");
                            setSize("50x100mm");
                          }}
                        >
                          50x100mm
                        </MenuItem>
                        <MenuItem
                          color={"black"}
                          onClick={() => {
                            setSizeOption("75x100mm");
                            setSize("75x100mm");
                          }}
                        >
                          75x100mm
                        </MenuItem>
                      </>
                    )}
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSizeOption("90mm round");
                        setSize("90mm round");
                      }}
                    >
                      90mm round
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSizeOption("custom");
                      }}
                    >
                      {`<type custom answer>`}
                    </MenuItem>
                  </MenuList>
                </Menu>

                {sizeOption === "custom" && (
                  <CustomInput
                    value={size}
                    onChange={(e) => {
                      setSize(e.target.value);
                    }}
                  />
                )}
                <FormLabel color={"black"} htmlFor="title">
                  What length?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 4 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  What Color?
                </FormLabel>
                <CustomInput
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  How many linear meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 5 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many ridge tiles?
                </FormLabel>
                <CustomInput
                  value={ridge}
                  onChange={(e) => {
                    setRidge(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  What Color?
                </FormLabel>
                <CustomInput
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  How many linear meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 6 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Roof Type?
                </FormLabel>

                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {roofType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("tiled roof")}
                    >
                      tiled roof
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("metal roof")}
                    >
                      metal roof
                    </MenuItem>
                  </MenuList>
                </Menu>

                <FormLabel color={"black"} htmlFor="title">
                  Valley’s material?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {zorc}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setZorc("Zincalume")}
                    >
                      Zincalume
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setZorc("Colorbond")}
                    >
                      Colorbond
                    </MenuItem>
                  </MenuList>
                </Menu>

                {zorc === "Colorbond" && (
                  <>
                    <FormLabel color={"black"} htmlFor="title">
                      What Colour?
                    </FormLabel>

                    <CustomInput
                      value={color}
                      onChange={(e) => {
                        setColor(e.target.value);
                      }}
                    />
                  </>
                )}

                <FormLabel color={"black"} htmlFor="title">
                  How many linear meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 7 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Roof Type?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {roofType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("tiled roof")}
                    >
                      tiled roof
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("metal roof")}
                    >
                      metal roof
                    </MenuItem>
                  </MenuList>
                </Menu>
                {roofType === "metal roof" && (
                  <>
                    <FormLabel color={"black"} htmlFor="title">
                      back tray material?
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        background={"black"}
                        color={"white"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        <Text maxW={300} noOfLines={5}>
                          {zorc}
                        </Text>
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          color={"black"}
                          onClick={() => setZorc("Zincalume")}
                        >
                          Zincalume
                        </MenuItem>
                        <MenuItem
                          color={"black"}
                          onClick={() => setZorc("Colorbond")}
                        >
                          Colorbond
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                )}

                {roofType === "metal roof" ? (
                  <Box my={4}>
                    <FormLabel color={"black"} htmlFor="title">
                      back tray’s length?
                    </FormLabel>
                    <CustomInput
                      value={meters}
                      onChange={(e) => {
                        setMeters(e.target.value);
                      }}
                    />
                  </Box>
                ) : (
                  <Box my={4}></Box>
                )}
                {zorc === "Colorbond" && (
                  <Box my={4}>
                    <FormLabel color={"black"} htmlFor="title">
                      back tray’s color?
                    </FormLabel>
                    <CustomInput
                      value={color}
                      onChange={(e) => {
                        setColor(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
            {titleSelect === 8 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many tiles?
                </FormLabel>
                <CustomInput
                  value={tile}
                  onChange={(e) => {
                    setTile(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  What type of tiles?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {tileType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setTileType("terracotta")}
                    >
                      terracotta
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setTileType("concrete")}
                    >
                      concrete
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormLabel color={"black"} htmlFor="title">
                  What brand?
                </FormLabel>
                <CustomInput
                  value={brand}
                  onChange={(e) => {
                    setBrand(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 9 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Linear meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  Is Valleys?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {isValley}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setIsValley("Yes")}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem color={"black"} onClick={() => setIsValley("No")}>
                      No
                    </MenuItem>
                  </MenuList>
                </Menu>
                {isValley === "Yes" && (
                  <>
                    {" "}
                    <FormLabel color={"black"} htmlFor="title">
                      Linear meters for valley?
                    </FormLabel>
                    <CustomInput
                      value={valleyMeter}
                      onChange={(e) => {
                        setValleyMeter(e.target.value);
                      }}
                    />
                  </>
                )}
              </Box>
            )}
            {titleSelect === 10 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Sheets Type?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {sheetType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setSheetType("TRIMDEK")}
                    >
                      TRIMDEK
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setSheetType("KLIPLOK")}
                    >
                      KLIPLOK
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setSheetType("corrugated")}
                    >
                      corrugated
                    </MenuItem>
                  </MenuList>
                </Menu>

                <>
                  <FormLabel color={"black"} htmlFor="title">
                    sheet material?
                  </FormLabel>
                  <Menu>
                    <MenuButton
                      background={"black"}
                      color={"white"}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                    >
                      <Text maxW={300} noOfLines={5}>
                        {zorc}
                      </Text>
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        color={"black"}
                        onClick={() => setZorc("Zincalume")}
                      >
                        Zincalume
                      </MenuItem>
                      <MenuItem
                        color={"black"}
                        onClick={() => setZorc("Colorbond")}
                      >
                        Colorbond
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>

                {zorc === "Zincalume" && (
                  <Box my={4}>
                    <FormLabel color={"black"} htmlFor="title">
                      square meters?
                    </FormLabel>
                    <CustomInput
                      value={meters}
                      onChange={(e) => {
                        setMeters(e.target.value);
                      }}
                    />
                  </Box>
                )}
                {zorc === "Colorbond" && (
                  <Box my={4}>
                    <FormLabel color={"black"} htmlFor="title">
                      square meters?
                    </FormLabel>
                    <CustomInput
                      value={meters}
                      onChange={(e) => {
                        setMeters(e.target.value);
                      }}
                    />
                    <FormLabel color={"black"} htmlFor="title">
                      color?
                    </FormLabel>
                    <CustomInput
                      value={color}
                      onChange={(e) => {
                        setColor(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
            {titleSelect === 11 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many cowls?
                </FormLabel>
                <CustomInput
                  value={cowls}
                  onChange={(e) => {
                    setCowls(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 12 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many sides?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {sides}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setSides("all 4-sides")}
                    >
                      all 4-sides
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setSides("3-sides only")}
                    >
                      3-sides only
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setSides("3-sides only")}
                    >
                      the top section only
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormLabel color={"black"} htmlFor="title">
                  What Colour?
                </FormLabel>
                <CustomInput
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 13 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Quantity?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  What Colour?
                </FormLabel>
                <CustomInput
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  Roof Type?
                </FormLabel>

                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {roofType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("tiled roof")}
                    >
                      tiled roof
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("metal roof")}
                    >
                      metal roof
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )}
            {titleSelect === 14 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Skylight Type?
                </FormLabel>

                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {skylightOption}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSkylightOption("Velux");
                        setSkylight("Velux");
                      }}
                    >
                      Velux
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSkylightOption("acrylic dome");
                        setSkylight("acrylic dome");
                      }}
                    >
                      acrylic dome
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSkylightOption("custom");
                        setSkylight("");
                      }}
                    >
                      type custom answer
                    </MenuItem>
                  </MenuList>
                </Menu>
                {skylightOption === "custom" && (
                  <CustomInput
                    value={skylight}
                    onChange={(e) => {
                      setSkylight(e.target.value.trim());
                    }}
                  />
                )}
                <FormLabel color={"black"} htmlFor="title">
                  What Colour?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {colorOption}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setColorOption("CLEAR/TRANSPARENT");
                        setColor("CLEAR/TRANSPARENT");
                      }}
                    >
                      CLEAR/TRANSPARENT
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setColorOption("OPAL");
                        setColor("OPAL");
                      }}
                    >
                      OPAL
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setColorOption("custom");
                        setColor("");
                      }}
                    >
                      type custom answer
                    </MenuItem>
                  </MenuList>
                </Menu>
                {colorOption === "custom" && (
                  <CustomInput
                    value={color}
                    onChange={(e) => {
                      setColor(e.target.value);
                    }}
                  />
                )}
                <FormLabel color={"black"} htmlFor="title">
                  Roof Type?
                </FormLabel>

                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {roofType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("tiled roof")}
                    >
                      tiled roof
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("metal roof")}
                    >
                      metal roof
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )}
            {titleSelect === 15 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Roof Type?
                </FormLabel>

                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {roofType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("tiled roof")}
                    >
                      tiled roof
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("metal roof")}
                    >
                      metal roof
                    </MenuItem>
                  </MenuList>
                </Menu>
                {roofType === "metal roof" && (
                  <>
                    <FormLabel color={"black"} htmlFor="title">
                      Zincalume or Colorbond
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        background={"black"}
                        color={"white"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        <Text maxW={300} noOfLines={5}>
                          {zorc}
                        </Text>
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          color={"black"}
                          onClick={() => setZorc("Zincalume")}
                        >
                          Zincalume
                        </MenuItem>
                        <MenuItem
                          color={"black"}
                          onClick={() => setZorc("Colorbond")}
                        >
                          Colorbond
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                )}
                {zorc === "Colorbond" && (
                  <>
                    <FormLabel color={"black"} htmlFor="title">
                      What Colour?
                    </FormLabel>

                    <CustomInput
                      value={color}
                      onChange={(e) => {
                        setColor(e.target.value);
                      }}
                    />
                  </>
                )}
                <FormLabel color={"black"} htmlFor="title">
                  How many linear meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 17 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Screw Types?
                </FormLabel>

                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {screwType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setScrewType("50mm T17s")}
                    >
                      50mm T17s
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setScrewType("65mm T17s")}
                    >
                      65mm T17s
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setScrewType("50mm Vortex")}
                    >
                      50mm Vortex
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setScrewType("65mm Vortex")}
                    >
                      65mm Vortex
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setScrewType("custom")}
                    >
                      Create custom type
                    </MenuItem>
                  </MenuList>
                </Menu>
                {screwType === "custom" && (
                  <>
                    <CustomInput
                      value={screwTypeValue}
                      onChange={(e) => {
                        setScrewTypeValue(e.target.value);
                      }}
                    />
                  </>
                )}

                <FormLabel color={"black"} htmlFor="title">
                  How many screws (approx):
                </FormLabel>
                <CustomInput
                  value={screwNum}
                  onChange={(e) => {
                    setScrewNum(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 18 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Colorbond or Zincalume?
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {zorc}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setZorc("Zincalume")}
                    >
                      Zincalume
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setZorc("Colorbond")}
                    >
                      Colorbond
                    </MenuItem>
                  </MenuList>
                </Menu>
                {zorc === "Colorbond" && (
                  <CustomInput
                    value={color}
                    onChange={(e) => {
                      setColor(e.target.value);
                    }}
                  />
                )}
                <FormLabel color={"black"} htmlFor="title">
                  How many sheets?
                </FormLabel>
                <CustomInput
                  value={sheet}
                  onChange={(e) => {
                    setSheet(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  Length of each sheet (approx.):
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 19 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Gutter length:
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 20 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many dozen of valley-clips?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 21 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Roof Type?
                </FormLabel>

                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {roofType}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("tiled roof")}
                    >
                      tiled roof
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => setRoofType("metal roof")}
                    >
                      metal roof
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormLabel color={"black"} htmlFor="title">
                  Box Gutter’s Length:
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  Colour:
                </FormLabel>
                <CustomInput
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 22 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many square meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 23 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many square meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  How many ridge tiles?
                </FormLabel>
                <CustomInput
                  value={ridge}
                  onChange={(e) => {
                    setRidge(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 24 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  Colour:
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {colorOption}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setColorOption("CLEAR/TRANSPARENT");
                        setColor("CLEAR/TRANSPARENT");
                      }}
                    >
                      CLEAR/TRANSPARENT
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setColorOption("OPAL");
                        setColor("OPAL");
                      }}
                    >
                      OPAL
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setColorOption("Dark Tint");
                        setColor("Dark Tint");
                      }}
                    >
                      Dark Tint
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormLabel color={"black"} htmlFor="title">
                  Roof Sheet Profile:
                </FormLabel>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Text maxW={300} noOfLines={5}>
                      {sheetTypeOption}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSheetType("TRIMDEK");
                        setSheetTypeOption("TRIMDEK");
                      }}
                    >
                      TRIMDEK
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSheetType("corrugated");
                        setSheetTypeOption("corrugated");
                      }}
                    >
                      corrugated
                    </MenuItem>
                    <MenuItem
                      color={"black"}
                      onClick={() => {
                        setSheetType("");
                        setSheetTypeOption("<type custom answer>");
                      }}
                    >
                      type custom answer
                    </MenuItem>
                  </MenuList>
                </Menu>
                {sheetTypeOption === "<type custom answer>" && (
                  <CustomInput
                    value={sheetType}
                    onChange={(e) => {
                      setSheetType(e.target.value);
                    }}
                  />
                )}
                <FormLabel color={"black"} htmlFor="title">
                  How many square meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 25 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many linear meters are in total?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
                <FormLabel color={"black"} htmlFor="title">
                  How many sheets?
                </FormLabel>
                <CustomInput
                  value={sheet}
                  onChange={(e) => {
                    setSheet(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 27 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many linear meters?
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
            {titleSelect === 28 && (
              <Box my={4}>
                <FormLabel color={"black"} htmlFor="title">
                  How many box gutter joints:
                </FormLabel>
                <CustomInput
                  value={meters}
                  onChange={(e) => {
                    setMeters(e.target.value);
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };
  const isNextDisabled = () => {
    switch (step) {
      case 1:
        return !(
          date &&
          ownerChoice &&
          ownerChoice !== "Press to Select" &&
          siteAddress &&
          observation &&
          observationExpress !== "Press to Select" &&
          buildingHeight !== "Press to Select" &&
          roofingIssue !== "Press to Select"
        );
      case 2:
        return sections.length === 0;
      case 3:
        return !(
          newJDText &&
          requiredAccessText &&
          newJDStoreyText &&
          accessOption
        );
      case 4:
        return jobDescription.length === 0;
      case 5:
        return !(men && effort && difficult && difficult != "Press to Select");
      case 6:
        return !warrantyNoteChoice;
    }
  };
  useEffect(() => {
    if (
      roofingIssueText &&
      roofingIssueText !== "Press to Select" &&
      buildingHeightText &&
      buildingHeightText !== "Press to Select" &&
      observationExpress &&
      observationExpress !== "Press to Select" &&
      !observation
    ) {
      setObservation(`We attended the site to inspect ${roofingIssueText}. We gained access to the ${buildingHeightText} for inspection.
      ${observationExpress ? `\n${observationExpress}` : ""}
      `);
    }
  }, [observationExpress, roofingIssueText, buildingHeightText, observation]);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <FormControl color={"gray.400"} isRequired>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                Quote Date
              </FormLabel>
              <CustomInput
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                type={"date"}
              />
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel htmlFor="The Owner" color={"black"}>
                Owner or Owner's Corporation
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  {ownerChoice}
                </MenuButton>
                <MenuList>
                  {ownerList.map((n) => {
                    return (
                      <MenuItem
                        color={"black"}
                        key={n}
                        onClick={() => setOwnerChoice(n)}
                      >
                        {n}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              {ownerChoice === ownerList[0] && (
                <Box mt={2}>
                  <Box w={"full"} pb={4}>
                    <FormLabel htmlFor="Real Estate" color={"black"}>
                      Strata
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        background={"black"}
                        color={"white"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        {strataChoice}
                      </MenuButton>
                      <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                        {strataList.map((n) => {
                          return (
                            <MenuItem
                              color={"black"}
                              key={n}
                              onClick={() => {
                                setStrataChoice(n);
                              }}
                            >
                              {n}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    {strataChoice === strataList[strataList.length - 1] && (
                      <CustomInput
                        value={strata}
                        onChange={(e) => {
                          setStrata(e.target.value);
                        }}
                      />
                    )}
                  </Box>
                  <FormLabel color={"black"} htmlFor="date">
                    Strata Plan Number
                  </FormLabel>
                  <CustomInput
                    value={strataPlan}
                    maxLength={50}
                    onChange={(e) => {
                      setStrataPlan(e.target.value);
                    }}
                  />
                </Box>
              )}
              {ownerChoice === ownerList[1] && (
                <Box mt={2}>
                  <Box w={"full"} pb={4}>
                    <FormLabel htmlFor="Real Estate" color={"black"}>
                      Real Estate
                    </FormLabel>
                    <Menu>
                      <MenuButton
                        background={"black"}
                        color={"white"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                      >
                        {realestateChoice}
                      </MenuButton>
                      <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                        {realestateList.map((n) => {
                          return (
                            <MenuItem
                              color={"black"}
                              key={n}
                              onClick={() => {
                                setRealestateChoice(n);
                              }}
                            >
                              {n}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    {realestateChoice === "Ray White" && (
                      <Box>
                        {" "}
                        <Menu>
                          <MenuButton
                            background={"black"}
                            color={"white"}
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                          >
                            {realestateSubChoice}
                          </MenuButton>
                          <MenuList maxHeight={"50vh"} overflowY={"scroll"}>
                            {rayWhiteList.map((n) => {
                              return (
                                <MenuItem
                                  color={"black"}
                                  key={n}
                                  onClick={() => {
                                    setRealestateSubChoice(n);
                                  }}
                                >
                                  {n}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Menu>
                      </Box>
                    )}
                    {realestateChoice ===
                      realestateList[realestateList.length - 1] && (
                      <CustomInput
                        value={realestate}
                        onChange={(e) => {
                          setRealestate(e.target.value);
                        }}
                      />
                    )}
                  </Box>
                  <FormLabel color={"black"} htmlFor="owner">
                    Please find and type Landlord's name
                  </FormLabel>
                  <CustomInput
                    value={owner}
                    placeholder="(auto prefil) Landlord"
                    maxLength={50}
                    onChange={(e) => {
                      setOwner(e.target.value);
                    }}
                  />
                </Box>
              )}
              {ownerChoice === ownerList[2] && (
                <Box mt={2}>
                  <FormLabel color={"black"} htmlFor="owner">
                    Please find and type Landlord's name
                  </FormLabel>
                  <CustomInput
                    value={owner}
                    placeholder="(auto prefil) Landlord"
                    maxLength={50}
                    onChange={(e) => {
                      setOwner(e.target.value);
                    }}
                  />
                  <FormLabel color={"black"} htmlFor="Phone">
                    Phone number
                  </FormLabel>
                  <CustomInput
                    value={landlordPhone}
                    maxLength={50}
                    onChange={(e) => {
                      setLandLordPhone(e.target.value);
                    }}
                  />
                  <FormLabel color={"black"} htmlFor="email">
                    Email Address
                  </FormLabel>
                  <CustomInput
                    value={landlordEmail}
                    maxLength={50}
                    onChange={(e) => {
                      setLandLordEmail(e.target.value);
                    }}
                  />
                </Box>
              )}
            </Box>

            {ownerChoice !== ownerList[2] && (
              <Box w={"full"} pt={4}>
                <FormLabel htmlFor="Job No." color={"black"}>
                  Job No.
                </FormLabel>
                <CustomInput
                  value={jobNo}
                  maxLength={50}
                  onChange={(e) => {
                    setJobNo(e.target.value);
                  }}
                />
              </Box>
            )}
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="site address">
                Site Address
              </FormLabel>
              <CustomInput
                value={siteAddress}
                maxLength={50}
                onChange={(e) => {
                  setSiteAddress(e.target.value);
                }}
              />
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="site address">
                What best describes the roofing issue
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text maxW={300} noOfLines={5}>
                    {roofingIssue}
                  </Text>
                </MenuButton>
                <MenuList>
                  {[
                    {
                      title: "One roof leak",
                      value: "the roof section above the leak area",
                    },
                    {
                      title: "Two roof leaks",
                      value: "the roof section above two roof leaks",
                    },
                    {
                      title: "Numerous roof leaks",
                      value: "the roof due to numerous roof leaks",
                    },
                    {
                      title: "Roof Inspection",
                      value:
                        "the roof to provide a quotation for roofing related repairs",
                    },
                    {
                      title: "<type custom answer>",
                      value: "",
                    },
                  ].map((n) => (
                    <MenuItem
                      key={n.title}
                      color={"black"}
                      onClick={() => {
                        setRoofingIssue(n.title);
                        setRoofingIssueText(n.value);
                      }}
                    >
                      {n.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              {roofingIssue === "<type custom answer>" && (
                <CustomInput
                  value={roofingIssueText}
                  maxLength={50}
                  onChange={(e) => {
                    setRoofingIssueText(e.target.value);
                  }}
                />
              )}
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="Building’s Height">
                Building’s Height?
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text maxW={300} noOfLines={5}>
                    {buildingHeight}
                  </Text>
                </MenuButton>
                <MenuList>
                  {[
                    {
                      title: "single-storey building",
                      value: "single-storey building",
                    },
                    {
                      title: "2-storey building",
                      value: "2-storey building",
                    },
                    {
                      title: "3-storey building",
                      value: "3-storey building",
                    },
                    {
                      title: "4-storey building",
                      value: "4-storey building",
                    },
                    {
                      title: "<type custom answer>",
                      value: "",
                    },
                  ].map((n) => (
                    <MenuItem
                      key={n.title}
                      color={"black"}
                      onClick={() => {
                        setBuildingHeight(n.title);
                        setBuildingHeightText(n.value);
                      }}
                    >
                      {n.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              {buildingHeight === "<type custom answer>" && (
                <CustomInput
                  value={buildingHeightText}
                  maxLength={50}
                  onChange={(e) => {
                    setBuildingHeightText(e.target.value);
                  }}
                />
              )}
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="Observation">
                Observation
              </FormLabel>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text maxW={300} noOfLines={5}>
                    {observationExpress}
                  </Text>
                </MenuButton>
                <MenuList>
                  {[
                    {
                      title:
                        "We provide a list of roofing-related repairs as per our detailed quotation below (including photos and step-by-step instructions).",
                      value:
                        "We provide a list of roofing-related repairs as per our detailed quotation below (including photos and step-by-step instructions).",
                    },
                    {
                      title:
                        "We provide a list of roofing-related repairs to carry out roof maintenance, as per our detailed quotation below (including photos and step-by-step instructions).",
                      value:
                        "We provide a list of roofing-related repairs to carry out roof maintenance, as per our detailed quotation below (including photos and step-by-step instructions).",
                    },
                  ].map((n) => (
                    <MenuItem
                      w={"300px"}
                      key={n.title}
                      color={"black"}
                      onClick={() => {
                        setObservationExpress(n.value);
                      }}
                    >
                      {n.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Box pt={4} />
              {observationType === "Customized Quote" && (
                <Textarea
                  h={250}
                  disabled={
                    !(
                      observationExpress &&
                      buildingHeight &&
                      observationExpress !== "Press to Select" &&
                      buildingHeight !== "Press to Select"
                    )
                  }
                  color={"black"}
                  value={observation}
                  focusBorderColor="black"
                  onChange={(e) => {
                    setObservation(e.target.value);
                  }}
                />
              )}
              {observationType === "Multiple Lots or Units" && (
                <>
                  <List>
                    {multiOb.map((n, i) => {
                      return (
                        <Stack
                          borderBottom={"1px solid"}
                          borderColor={"gray.300"}
                          py={2}
                          key={i.toString()}
                          position={"relative"}
                        >
                          <Badge
                            onClick={() => {
                              handleRemoveMultiOb(i);
                            }}
                            cursor={"pointer"}
                            position={"absolute"}
                            right={0}
                            top={0}
                            colorScheme="red"
                          >
                            Remove
                          </Badge>
                          <ListItem>{n.title || "<blank>"}</ListItem>
                          <Box pl={4} whiteSpace={"pre-line"}>
                            {n.jds.map((j) => {
                              return (
                                <Box>
                                  -&nbsp;&nbsp;&nbsp;&nbsp;{`${j.title}`}
                                </Box>
                              );
                            })}
                          </Box>
                        </Stack>
                      );
                    })}
                  </List>
                  <Menu>
                    <MenuButton
                      background={"black"}
                      color={"white"}
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                    >
                      <Text maxW={300} noOfLines={5}>
                        {unitType}
                      </Text>
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        color={"black"}
                        onClick={() => setUnitType("Unit")}
                      >
                        Unit
                      </MenuItem>
                      <MenuItem
                        color={"black"}
                        onClick={() => setUnitType("Lots")}
                      >
                        Lots
                      </MenuItem>
                      <MenuItem
                        color={"black"}
                        onClick={() => setUnitType("Villa")}
                      >
                        Villa
                      </MenuItem>
                      <MenuItem
                        color={"black"}
                        onClick={() => setUnitType("TH")}
                      >
                        TH
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <FormLabel pt={2} color={"black"} htmlFor="title">
                    Number?
                  </FormLabel>
                  <CustomInput
                    value={unitNum}
                    onChange={(e) => {
                      setUnitNum(e.target.value);
                    }}
                  />
                  <Box pt={4} />
                  <PrimaryButton
                    disabled={!unitNum}
                    onClick={() => {
                      setIsObOpen(true);
                      setNewJDTitle("");
                      setNewJDText("");
                    }}
                    title="Add item"
                  />
                </>
              )}
            </Box>
          </FormControl>
        );
      case 2:
        return (
          <Stack flex={1} textAlign={"center"}>
            <Text textAlign={"center"} variant={"title_l"} py={4}>
              Add Observation sections
            </Text>
            {sections.map((n, i) => {
              return (
                <Stack
                  borderBottom={"1px solid"}
                  borderColor={"gray.300"}
                  py={2}
                  key={i.toString()}
                  position={"relative"}
                >
                  <Badge
                    onClick={() => {
                      handleRemoveSection(i);
                    }}
                    cursor={"pointer"}
                    position={"absolute"}
                    right={0}
                    top={0}
                    colorScheme="red"
                  >
                    Remove
                  </Badge>
                  <Text>{n.description}</Text>
                  <Image src={n.src} />
                </Stack>
              );
            })}
            {isUploading && <Text>Image uploading...</Text>}
            <IconButton
              isLoading={isUploading}
              onClick={() => {
                setImgSrc("");
                setIsOpen(true);
              }}
              colorScheme="blue"
              aria-label="Add"
              size="lg"
              icon={<AddIcon />}
            />
          </Stack>
        );
      case 3:
        return (
          <Stack flex={1} w={"full"}>
            <Stack
              borderBottom={"1px solid"}
              borderColor={"gray.300"}
              py={2}
              position={"relative"}
            >
              <Box>{"How many storeys is this building"}</Box>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text maxW={300} noOfLines={5}>
                    {storeyOption}
                  </Text>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setStoreyOption("single-storey building");
                      setNewJDStoreyText(`This is a single-storey building.`);
                    }}
                  >
                    single-storey building
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setStoreyOption("2-storey building");
                      setNewJDStoreyText(`This is a 2-storey building.`);
                    }}
                  >
                    2-storey building
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setStoreyOption("3-storey building");
                      setNewJDStoreyText(`This is a 3-storey building.`);
                    }}
                  >
                    3-storey building
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setStoreyOption("4-storey building");
                      setNewJDStoreyText(`This is a 4-storey building.`);
                    }}
                  >
                    4-storey building
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setStoreyOption("custom");
                      setNewJDStoreyText("");
                    }}
                  >
                    {`<type customer answer>`}
                  </MenuItem>
                </MenuList>
              </Menu>
              {storeyOption === "custom" && (
                <CustomInput
                  value={newJDStoreyText}
                  onChange={(e) => {
                    setNewJDStoreyText(e.target.value);
                  }}
                />
              )}
              <Box>{"Recommended roof access:"}</Box>
              <Menu>
                <MenuButton
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text maxW={300} noOfLines={5}>
                    {accessOption}
                  </Text>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setAccessOption("single-storey ladder");
                      setNewJDText(
                        `We will use a single-storey ladder to access the roof to carry out this job.`
                      );
                    }}
                  >
                    single-storey ladder
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setAccessOption("2-storey ladder");
                      setNewJDText(
                        `We will use a 2-storey ladder to access the roof to carry out this job.`
                      );
                    }}
                  >
                    2-storey ladder
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setAccessOption("3-storey ladder");
                      setNewJDText(
                        `We will use a 3-storey ladder to access the roof to carry out this job.`
                      );
                    }}
                  >
                    3-storey ladder
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setAccessOption("4-storey ladder");
                      setNewJDText(
                        `We will use a 4-storey ladder ladder to access the roof to carry out this job.`
                      );
                    }}
                  >
                    4-storey ladder
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setAccessOption("a maintenance hole");
                      setNewJDText(
                        `We will use a single-storey ladder to access the roof via the maintenance hole at the top of the staircase to carry out this job.`
                      );
                    }}
                  >
                    a maintenance hole
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setAccessOption("electric scissor lift");
                      setNewJDText(
                        `We will use an electric scissor lift to access the roof to carry out this job.`
                      );
                    }}
                  >
                    electric scissor lift
                  </MenuItem>
                  <MenuItem
                    color={"black"}
                    onClick={() => {
                      setAccessOption(
                        "39FT diesel self-levelling scissor lift"
                      );
                      setNewJDText(
                        `We will use a 39FT diesel self-levelling scissor lift (1.85 meters wide, 5.4 meters long and 2.85 meters tall) to access the roof to carry out this job.`
                      );
                    }}
                  >
                    39FT diesel self-levelling scissor lift
                  </MenuItem>
                </MenuList>
              </Menu>
              <Textarea
                value={newJDText}
                focusBorderColor="black"
                color={"black"}
                onChange={(e) => {
                  setNewJDText(e.target.value);
                }}
              />
            </Stack>
            <FormLabel htmlFor="required-home" color={"black"}>
              Residents required to be home?
            </FormLabel>
            <Menu>
              <MenuButton
                background={"black"}
                color={"white"}
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {requiredAccess}
              </MenuButton>
              <MenuList>
                {["Yes", "No"].map((n) => {
                  return (
                    <MenuItem
                      color={"black"}
                      key={n}
                      onClick={() => {
                        setRequiredAccess(n);
                        if (n === "No") {
                          setRequiredAccessText(
                            "No access inside the property is required - the roof is accessible at any time."
                          );
                        } else {
                          setRequiredAccessText(
                            "We will require access inside the property to do this job."
                          );
                        }
                      }}
                    >
                      {n}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
            <Text fontSize={"sm"}>Provide specification client's info</Text>
            <Stack
              borderBottom={"1px solid"}
              borderColor={"gray.300"}
              py={2}
              position={"relative"}
            >
              <Textarea
                value={requiredAccessText}
                focusBorderColor="black"
                color={"black"}
                onChange={(e) => {
                  setRequiredAccessText(e.target.value);
                }}
              />
            </Stack>
          </Stack>
        );
      case 4:
        return (
          <Stack flex={1}>
            <Text textAlign={"center"} variant={"title_l"} py={4}>
              Add Job Descriptions
            </Text>

            <List>
              {jobDescription.map((n, i) => {
                return (
                  <Stack
                    borderBottom={"1px solid"}
                    borderColor={"gray.300"}
                    py={2}
                    key={i.toString()}
                    position={"relative"}
                  >
                    <Badge
                      onClick={() => {
                        handleRemoveJD(i);
                      }}
                      cursor={"pointer"}
                      position={"absolute"}
                      right={0}
                      top={0}
                      colorScheme="red"
                    >
                      Remove
                    </Badge>
                    <ListItem whiteSpace={"pre-line"}>
                      {n.title || "<blank>"}
                    </ListItem>
                    <Box whiteSpace={"pre-line"}>{n.description}</Box>
                  </Stack>
                );
              })}
            </List>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="title">
                Title
              </FormLabel>
              {renderOptionContent()}
              <PrimaryButton
                onClick={() => handlePrefill(titleSelect)}
                title="Prefill"
              />
              <CustomInput
                value={newJDTitle}
                onChange={(e) => {
                  setNewJDTitle(e.target.value);
                }}
              />
            </Box>
            <FormLabel color={"black"} htmlFor="title">
              Description
            </FormLabel>
            <Textarea
              h={"250px"}
              value={newJD}
              focusBorderColor="black"
              color={"black"}
              onChange={(e) => {
                setNewJD(e.target.value);
              }}
            />

            <IconButton
              onClick={() => {
                handleAddJDSection();
              }}
              colorScheme="blue"
              aria-label="Add"
              size="lg"
              icon={<AddIcon />}
            />
          </Stack>
        );
      case 5:
        return (
          <Stack flex={1} w={"full"}>
            <FormLabel color={"black"} htmlFor="title">
              How many men?
            </FormLabel>
            <CustomInput
              type={"number"}
              min={1}
              max={10}
              value={men}
              onChange={(e) => {
                setMen(e.target.value);
              }}
            />
            <FormLabel color={"black"} htmlFor="title">
              Time?
            </FormLabel>
            <Stack direction={"row"} alignItems={"center"}>
              <CustomInput
                value={effort}
                onChange={(e) => {
                  setEffort(e.target.value);
                }}
              />
              <Box>
                <Menu>
                  <MenuButton
                    background={"black"}
                    color={"white"}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    {unit}
                  </MenuButton>
                  <MenuList>
                    {["hours", "days"].map((n) => {
                      return (
                        <MenuItem
                          color={"black"}
                          key={n}
                          onClick={() => {
                            setUnit(n);
                          }}
                        >
                          {n}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
            </Stack>

            <FormLabel htmlFor="required-home" color={"black"}>
              Difficulty Level?
            </FormLabel>
            <Menu>
              <MenuButton
                background={"black"}
                color={"white"}
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {difficult}
              </MenuButton>
              <MenuList>
                {[
                  "Straightforward job. An average skill is required.",
                  "A challenging job requires a skilled professional but is still manageable.",
                  "Ultra difficult job. It can be done, but I would rather avoid it.",
                ].map((n) => {
                  return (
                    <MenuItem
                      w={"300px"}
                      color={"black"}
                      key={n}
                      onClick={() => {
                        setDifficult(n);
                      }}
                    >
                      {n}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
            <FormLabel htmlFor="required-estimation" color={"black"}>
              Estimated expenses?
            </FormLabel>
            <CustomInput
              min={0}
              value={estimation}
              onChange={(e) => {
                setEstimation(e.target.value);
              }}
            />

            <FormLabel htmlFor="required-roof-age" color={"black"}>
              What is the minimum roof age(year)?
            </FormLabel>
            <CustomInput
              min={0}
              value={warrantyYear}
              onChange={(e) => {
                setWarrantyYear(e.target.value);
              }}
            />
          </Stack>
        );
      case 6:
        return (
          <Stack flex={1} w={"full"}>
            <FormLabel color={"black"} htmlFor="title">
              Select one of these two disclaimers:
            </FormLabel>
            <RadioGroup
              onChange={(val) => setWarrantyNoteChoice(val)}
              value={warrantyNoteChoice}
            >
              <Radio colorScheme={"green"} value="A">
                Option A: A professional but mild disclaimer indicating we are
                taking responsibility for our work and the roof should be ok;
                however, it will require ongoing maintenance:
              </Radio>
              <Box h={10} />
              <Radio colorScheme={"green"} value="B">
                Option B: You give the client a heads-up stating that there is a
                high chance of roof leaks recurring and that we cannot warranty
                any leaks (usually due to poor roof condition or too many leaks
                with mould or stain marks). NOTE: It lowers our chances of
                getting our Quote approval:
              </Radio>
            </RadioGroup>
            {warrantyNoteChoice === "B" && (
              <>
                <FormLabel color={"black"} htmlFor="title">
                  Would you like to also add this line?
                </FormLabel>
                <FormLabel color={"black"} htmlFor="title">
                  (it’s a strong message - use it only when there is lots of
                  mould, multiple roof leaks, and the roof is in a very poor
                  condition (but we cannot replace it as it’s a big job). In
                  other words, you protect yourself and the business by being
                  upfront with the client regarding the high chance of leaks
                  recurring. Remember that after this statement, the chances of
                  getting the Quotation approved substantially decrease.
                  However, it's sometimes better to miss out on a job and be
                  safe rather than deal with brutal recalls later).
                </FormLabel>
                <Text>
                  Suppose the owner or the owner’s corporation wishes to have a
                  warranty on all leaks and mould developments. In that case, we
                  recommend engaging a builder, conducting a major overhaul to
                  the roof (possibly roof replacement) and gutters, and
                  replacing all water-damaged, mould-infected ceiling sections
                  internally.
                </Text>
              </>
            )}
            {warrantyNoteChoice === "B" && (
              <Checkbox
                iconColor="black"
                size={"lg"}
                isChecked={warrantyNotePlus}
                onChange={(e) => setWarrantyNotePlus(e.target.checked)}
              >
                ADD
              </Checkbox>
            )}
          </Stack>
        );
      case 7:
        return (
          <Stack flex={1} w={"full"}>
            <FormLabel color={"black"} htmlFor="title">
              Was there a bad stain mark or mould on the ceiling (not the
              walls)?
            </FormLabel>

            <Checkbox
              size={"lg"}
              iconColor="black"
              onChange={(e) => setCeilingTreatment(e.target.checked)}
              isChecked={ceilingTreatment}
            >
              YES
            </Checkbox>
          </Stack>
        );
      case 8:
        return (
          <Stack flex={1} w={"full"}>
            <FormLabel color={"black"} htmlFor="title">
              Is there any bad mould, loose render, or peeling (or bubbling)
              paint in the render of the vertical brick wall (not plasterboard
              or gyprock)? This sometimes occurs in terraces or houses which are
              over 80 years old.
            </FormLabel>

            <Checkbox
              size={"lg"}
              iconColor="black"
              onChange={(e) => setPaintTreatment(e.target.checked)}
              isChecked={paintTreatment}
            >
              YES
            </Checkbox>
          </Stack>
        );
      case 9:
        return (
          <Stack flex={1} w={"full"}>
            <FormLabel color={"black"} htmlFor="title">
              Did you observe any evidence of inadequate ventilation
              (condensation developing in the premises, such as water droplets
              on the ceiling or walls or usually close to the dry cleaners or
              bathrooms)?
            </FormLabel>

            <Checkbox
              size={"lg"}
              iconColor="black"
              onChange={(e) => setImportantNote(e.target.checked)}
              isChecked={importantNote}
            >
              YES
            </Checkbox>
          </Stack>
        );
      default:
        return <Box></Box>;
    }
  };
  const handleAddJDSection = () => {
    setJobDescription([
      ...jobDescription,
      { title: newJDTitle, description: newJD, price: JDPrice },
    ]);
    calculateExpense([
      ...jobDescription,
      { title: newJDTitle, description: newJD, price: JDPrice },
    ]);
    setNewJD("");
    setNewJDTitle("");
    setJDPrice(0);
  };

  const calculateExpense = (jds: JobDescription[]) => {
    let total = jds.reduce((pre, next) => {
      return pre + (next.price ?? 0);
    }, 0);
    if (accessOption.includes("39FT diesel self-levelling scissor lift")) {
      total += 1000;
    }
    setEstimation(total.toFixed(0));
  };

  return (
    <Stack
      overflow={"hidden"}
      borderRadius={12}
      direction={"row"}
      boxShadow={"lg"}
      w={"100%"}
      background={"white"}
      maxWidth={"528px"}
      m={"auto"}
      mt={6}
      pb={12}
    >
      <Box py={12} flex={1}>
        <Box p={4} margin={"auto"} maxW={"360px"}>
          <Text variant={"title_2xl"} textAlign={"center"} pb={6}>
            Submit a quote
          </Text>
          <Box
            cursor={"pointer"}
            onClick={() => {
              window.localStorage.removeItem(cacheKey);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }}
          >
            <Text
              textDecoration={"underline"}
              variant={"title_l"}
              textAlign={"center"}
              pb={6}
              color={"red.300"}
            >
              Clear Draft
            </Text>
          </Box>
          <Progress
            colorScheme="green"
            size="md"
            value={(step / totalSteps) * 100}
          />
          <Stack flex={1} align={"center"}>
            {renderContent()}
            <Stack
              w={"full"}
              justifyContent={"space-between"}
              direction={"row"}
              pt={6}
            >
              <Button
                borderRadius={"12px"}
                boxShadow={"lg"}
                w={"full"}
                py={7}
                colorScheme="blackAlpha"
                disabled={step <= 1}
                onClick={() => setStep(step - 1)}
              >
                Back
              </Button>
              <PrimaryButton
                isLoading={isLoading}
                onClick={() => {
                  if (step === 4 && newJDTitle && newJD) {
                    handleAddJDSection();
                  }

                  step < totalSteps ? setStep(step + 1) : handleSubmit();
                }}
                disabled={isNextDisabled()}
                title={step < totalSteps ? "Next" : "Submit"}
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Modal
        size={"xl"}
        isCentered
        onClose={() => {
          setIsObOpen(false);
          setNewJDTitle("");
          setNewJDText("");
        }}
        isOpen={isObOpen}
      >
        <ModalOverlay background={"transparent"} />

        <ModalContent alignItems={"center"} py={8} background={"white"}>
          <ModalCloseButton m={2} size={"md"} />

          <Stack overflow={"auto"} w={"full"} py={8} px={4}>
            <List>
              {multiObItems.map((n, i) => {
                return (
                  <Stack
                    borderBottom={"1px solid"}
                    borderColor={"gray.300"}
                    py={2}
                    key={i.toString()}
                    position={"relative"}
                  >
                    <Badge
                      onClick={() => {
                        handleRemoveMultiObItem(i);
                      }}
                      cursor={"pointer"}
                      position={"absolute"}
                      right={0}
                      top={0}
                      colorScheme="red"
                    >
                      Remove
                    </Badge>
                    <ListItem>{n.title || "<blank>"}</ListItem>
                  </Stack>
                );
              })}
            </List>
            <FormLabel color={"black"} htmlFor="title">
              Select Description
            </FormLabel>
            {renderOptionContent()}
            {titleSelect === 0 && (
              <CustomInput
                value={newJDTitle}
                onChange={(e) => {
                  setNewJDTitle(e.target.value);
                }}
              />
            )}
            <PrimaryButton
              onClick={() => {
                handlePrefill(titleSelect, true);
              }}
              title="Add Description"
            />
            <PrimaryButton
              title="Save"
              onClick={() => handleSaveMultipleOb()}
            />
          </Stack>
        </ModalContent>
      </Modal>
      <Modal
        size={"xl"}
        isCentered
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
      >
        <ModalOverlay background={"transparent"} />

        <ModalContent alignItems={"center"} py={8} background={"white"}>
          <ModalCloseButton m={2} size={"md"} />

          <Stack px={4}>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                Description
              </FormLabel>
              <Menu>
                <MenuButton
                  // maxW={40}
                  background={"black"}
                  color={"white"}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text maxW={300} noOfLines={5}>
                    {descriptionOption}
                  </Text>
                </MenuButton>
                <MenuList
                  maxHeight={"50vh"}
                  maxW={"100vw"}
                  overflowX={"scroll"}
                  overflowY={"scroll"}
                >
                  {descriptionOptions.map((n, i) => {
                    return (
                      <MenuItem
                        color={"black"}
                        key={n}
                        onClick={() => {
                          setDescription(n);
                          setDescriptionOption(n);
                        }}
                      >
                        {n}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              <CustomInput
                maxLength={120}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Box>
            <Box w={"full"} pt={4}>
              <FormLabel color={"black"} htmlFor="date">
                Upload Image
              </FormLabel>
              <input
                value={imgSrc ? undefined : ""}
                type="file"
                accept="image/*"
                onChange={onSelectFile}
              />
              <Cropper
                style={{ height: 300, width: "100%" }}
                // zoomTo={1}
                aspectRatio={aspect}
                preview=".img-preview"
                src={imgSrc}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCrop(instance);
                }}
                guides={true}
              />
            </Box>
            <PrimaryButton
              title="Save"
              onClick={() => {
                handleSaveSection();
              }}
            />
          </Stack>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
